/* Default styles for mobile view */
.responsive-reservation .desktop {
    display: none;
  }
  
  .responsive-reservation .mobile {
    display: block;
  }
  
  /* Styles for tablets and desktop view */
  @media (min-width: 768px) {
    .responsive-reservation .desktop {
      display: block;
    }
    .responsive-reservation .mobile {
      display: none;
    }
  }
  