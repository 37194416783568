.home-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    padding: 0;
}
  
.home-image {
    max-width: 47%;
    height: auto;
    margin-top: 25px; 
    margin-left: 100px;
    margin-bottom: 100px;
}
  
/* Mobile styles */
@media (max-width: 768px) {
    .home-container {
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    .home-image {
        max-width: 100%;
        margin-top: 25px;
        margin-bottom: 50px;
        margin-left: -40px;
    }
}
