/* Desktop styles */

.mobile-menu-icon{
  display: none;
}

.sidebar {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.sidebar-item {
  padding: 5px;
  
  font-size: 30px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: -18px;
  font-family: 'Times New Roman', Times, serif;
}

.sidebar-item h2 {
  font-size: 35px;
  margin-top: 10px;
}

.sidebar-item a {
  margin-bottom: 20px;
  text-decoration: none;
  padding-top: 20px;
  color: inherit; 
}

/* Style for the submenu items */
.sidebar-item ul {
  font: inherit;
  list-style-type: none;
  padding-left: 0;  
  margin-left: 13px;
  margin-top: 13px;
  margin-bottom: 13px;
  
}

.sidebar-item li {
  font-size: 22px;  /* Smaller font for submenu items */
  margin-top: 10px;  /* Space between submenu items */
  
}





/* Mobile styles */
@media (max-width: 768px) {
  .sidebar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  .mobile-menu-icon {
    font-size: 30px;
    cursor: pointer;
    margin-right: 20px;
    display: block;
    position: relative;
    top: 1px;
    right: 0px;
    z-index: 1001; /* Ensure it's on top of other elements */
  }

  .hamburger-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 22px;
    width: 22px;
  }
  
  .hamburger-icon .line {
    width: 100%;
    height: 1px; /* Adjust this value to make the lines thinner or thicker */
    background-color: black;
  }
  

  .mobile-sidebar-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000; /* Adding a high z-index value here */
  }

  .mobile-sidebar-content.open {
    transform: translateX(0);
  }

  .sidebar-item,
  .sidebar-item h2,
  .sidebar-item a,
  .sidebar-item ul,
  .sidebar-item li {
    margin: 0;
    padding: 3px;

  }

  .sidebar-item h2 {
    font-size: 26px;
    width: 100%;
    text-align: center;
    padding: 0;
    margin-left: 15px;
    margin-top: 3px;
    margin-bottom: -10;
  }

  .sidebar-item {
    width: 100%;
    text-align: center;
  }
}

