/* Your existing desktop-first styles */

body, html {
  margin: 0;
  padding: 0;
  background-color: white;
}

.App-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-left: 60px;
  margin-top: 40px;
}

.App {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.Footer {
  width: 100%;
  text-align: left;
}

.page-content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.page-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.Portfolio, .About {
  flex: 1 1 auto;
  padding: 20px;
}

/* Mobile-first styles */

@media (max-width: 768px) {
  .App-container {
    margin-left: 0; /* Removing left margin for mobile view */
    margin-top: 0; /* Removing top margin for mobile view */
  }
  
  .App {
    flex-direction: column;
    margin-left: 10px;
  }
  
  .page-content-wrapper, .Footer {
    width: 100%;
  }

  .page-content {
    padding: 10px;
    overflow-y: scroll;
  }
  
  .Portfolio, .About {
    margin-left: 10px;
    padding: 10px;
  }

  .mobile-pricing-page {
    display: block; /* Ensuring the pricing page is displayed on mobile */
  }
}

@media (min-width: 769px) {
  .mobile-pricing-page {
    display: none; /* Hiding the pricing page on non-mobile devices */
  }
}