/* ReservationMobile.css */

.reservation-mobile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.calendar-mobile-container {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
}

.calendar-mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.calendar-mobile-header button {
    background: none;
    border: none;
    font-size: 18px;
}


.days-container-mobile {
    display: flex;
    overflow-x: scroll;
}

.days-container-mobile .mobile-day {
    min-width: calc(100% / 7); /* Ensuring each day takes up an equal portion of the container width */
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px; /* Space between each day */
}

.mobile-day:last-child {
    margin-right: 0; /* Removing the margin from the last day to eliminate the gap at the end */
}

.mobile-day {
    cursor: pointer;
}

.mobile-day.selected { 
    position: relative;
}

.mobile-day.selected::before {
    content: "";
    position: absolute;
    background-color: black;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; /* Ensures the black dot appears below the day number */
}

.mobile-day.selected > div { 
    color: white;
    z-index: 2; /* Ensures the day number appears above the black dot */
    position: relative;
}

.selected-date {
    margin: 16px 0;
    padding: 4px 8px;
    background-color: black;
    color: white;
    border-radius: 4px;
}

.available-times-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.clickable-time-mobile, .non-clickable-time-mobile {
    width: 100%;
    padding: 8px;
    margin-bottom: 8px;
    text-align: left;
    text-indent: 20px;
    cursor: pointer;
    border-bottom: 1px solid rgb(151, 150, 150);
}

.non-clickable-time-mobile {
    color: #444;
    cursor: default;
}
