/* Dashboard.css */

.container {
    
    flex-direction: column;
    align-items: center;
    padding: 2em;
    font-family: Arial, sans-serif;
}

.admin-login,
.admin-dashboard {
    width: 100%;
    max-width: 800px;
    border: 2px solid #000;
    padding: 2em;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-login {
    border: none;
}

.admin-login h1,
.admin-dashboard h1 {
    margin-bottom: 1em;
    font-weight: bold;
}

.admin-login input,
.admin-login button,
.admin-dashboard button {
    padding: 0.5em;
    margin: 0.5em 0;
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
}

.admin-login button,
.admin-dashboard button {
    background-color: #fff;
    transition: background-color 0.3s ease;
}

.admin-login button:hover,
.admin-dashboard button:hover {
    background-color: #f0f0f0;
}

/* Table styles */

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2em;
}

th,
td {
    padding: 1em;
    border-bottom: 1px solid #000;
    text-align: left;
}

th {
    background-color: #f8f8f8;
    font-weight: bold;
}

/* Layout for two tables side-by-side */

.admin-dashboard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: none;
}

.admin-dashboard > div {
    width: 100%;  /* Adjusted to accommodate the space between */
}

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em; /* Adjust margin as needed */
}

.refresh-button,
.add-button {
    padding: 0.5em 1em;  /* Adjust padding to control the width */
    width: 80px;
    margin: 0.5em 0;
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.3s ease;
}

.refresh-button:hover,
.add-button:hover {
    background-color: #f0f0f0;
}