.linkedinphoto-container {
    display: flex;
    overflow-x: auto; /* Enable horizontal scroll */
    white-space: nowrap; /* Keep inline-block items on the same line */
  
    /* Add optional styles for better appearance */
    gap: 16px;
    padding: 20px 0;
    margin-top: 70px;
}
  
.linkedinphoto-container img {
    flex: 0 0 auto;  /* This ensures the image doesn't shrink */
    width: 500px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
    .linkedinphoto-container {
        flex-direction: column; /* Stack images vertically */
        overflow-x: hidden; /* Remove horizontal scroll */
        align-items: center; /* Center images horizontally */
        padding: 10px 0; /* Adjust padding for smaller screens */
        margin-top: 0;
        margin-left: 0;
    }

    .linkedinphoto-container img {
        width: 80%; /* Make images responsive to the container's width */
        margin-bottom: 16px; /* Add space between images */
    }
}
