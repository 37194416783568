.about-container {
    max-width: 800px;
    /* margin: 50px auto; */
    padding: 20px;
    font-family: 'Times New Roman', Times, serif;
    margin-top: 30px;
    margin-left: 50px;
  }
  
  h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
  }
  
  p {
    font-size: 1em;
    line-height: 1.5;
    color: #555;
  }
  
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-top: 30px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  