.footer {
  background-color: white;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  margin-top: -20px;
}

.footer-content {
  display: flex;
  align-items: center;
  margin-top: -20px;
}


.footer-content p {
  text-align: left;
  margin-right: 20px; /* Add some space between the text and the icon */
}

.social-icon {
  width: 30px;
  height: 30px;
  margin-left: 15px;  /* Pushes the icon to the left corner */
  margin-top: 0px;
}
