.calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
  margin-top: 100px;
  width: 1200px;
  height: 90%
}

.calendar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 20px;
  margin-bottom: 20px;
}

.calendar-header button {
  margin: 0 20px;  /* Adjust margin as needed */
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  border: 1px solid #ccc;  /* Add border to the outer calendar */
  grid-gap: 0;  /* Remove gap between grid items */
}

.day-names {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.day-name {
  flex: 1;
  text-align: center;
}

.calendar-day,
.calendar-day-header {
  text-align: center;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 20px;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;  /* Keeps the header at top and other content at bottom */
  align-items: center;
}

.calendar-day-header {
  justify-content: flex-start;  /* Aligns header content at the top */
}

.calendar-day .day-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;  /* Centers this specific content vertically */
  align-items: center;  /* Centers this specific content horizontally */
}


/* For the last column items, we remove the right border */
.calendar-day:nth-child(7n),  
.calendar-day-header:nth-child(7n) {
  border-right: none;  
}


.calendar-day:nth-child(7n) .day-header {
  color: dodgerblue;
}

.calendar-day.empty {
  background-color: #f2f2f2;
  color: grey;
}


.available-times {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  font-size: 12.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Add this class to clickable times */
.clickable-time {
  cursor: pointer;
}

/* Add this class to non-clickable times */
.non-clickable-time {
  cursor: default;
  pointer-events: none; /* This ensures that the element and its children do not trigger click events */
}

.available-times div {
  margin-bottom: 4px;
}

.today {
  background-color: #f2f2f2;
  box-shadow: inset 0 0 0 2px #4CAF50;
}

.calendar-day.past {
  color: lightgrey;
  cursor: not-allowed;
}
